import React, { useEffect } from "react"

import Layout from "../components/layout"
import { getUser } from "../utils/auth"
import { navigate } from "gatsby"
import { AdminControls } from "identity-frontend"

export default function AdminPage() {
  useEffect(() => {
    const user = getUser()
    if (!user || !user.scopes.includes("admin")) {
      navigate("/")
    }
  })

  return (
    <Layout>
      <h2>Admin</h2>
      <hr />
      <AdminControls />
    </Layout>
  )
}
